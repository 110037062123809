import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import Chart from 'chart.js';
import { LocalService } from '../../shared/service/local.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  accesstoken;


  constructor(private toastr: ToastrService,private router:Router , private local:LocalService) {

  }

  ngOnInit() {

  }

  logout(){
    console.log('logout :>> ' );
    
    this.router.navigate([''])
    this.toastr.success("Logout Successfully!!","",{
      closeButton:false,
      positionClass:'toast-top-left'
  })
    this.local.setlocalstorage("islogin",false)
    this.local.removelocalstorage('access_token');
    this.accesstoken=this.local.getAccessToken()
    console.log(this.accesstoken);
    
  }


}
