import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  state = false;

  constructor(private login:LoginService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });






  toggle() {
    this.state = !this.state;
    if (this.state) {
      document.getElementById(
        "password").
        setAttribute("type", "password");

    } else {
      document.getElementById(
        "password").
        setAttribute("type", "text");

    }
  }

  // error(){
  //   document.getElementById("login_error").style.display = "none";
  // }

  onSubmit() {

    this.loginForm.value
    this.login.doLogin(this.loginForm.value).subscribe((data) => {
      // console.log("subsucribe", data);
      // console.log("status",data.status);
      
     
      
    })
    this.loginForm.reset()
  }

}
