import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { LocalService } from '../shared/service/local.service';
import { LockerService } from '../shared/service/locker.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private toastr: ToastrService,private locker: LockerService, private local: LocalService, private router: Router,private spinner: NgxSpinnerService) { }

    doLogin(formdata) {
        this.spinner.show()
        let requestData = {
            email: formdata.email,
            password: formdata.password
        }
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/account/login', requestData, null, null, null, false).subscribe(response => {
                // console.log(response);
                if (response.status == 400 || response.status == 401) {
                    this.spinner.hide()
                    // this.toastr.error("Login ID and password is not correct","",{
                    //     closeButton:true,
                    //     positionClass:'toast-top-left'
                    // })
                    // alert("username and password is incorrect")
                    // document.getElementById("login_error").style.display = "block";
                }
                if (response.status == 200) {
                    this.toastr.success("Login Successfully!! Welcome to Shot Locker Admin Panel","",{
                        closeButton:false,
                        positionClass:'toast-top-left'
                    })
                    this.spinner.hide()
                    this.router.navigate(['user']);
                    // alert("Login Sucessfully");


                } else {
                    this.spinner.hide()
                    this.toastr.error("Email ID and Password is not correct","",{
                        closeButton:false,
                        positionClass:'toast-top-left'
                    })
                    // alert("username and password is incorrect")
                }

                this.local.storeAccessToken(response.body['access_token']);
                this.local.setlocalstorage("islogin", true)
                observe.next(response);
                //   this.router.navigate(['dashboard'])
                //  alert("Login Sucessfully")
            })
        })

    }
}
